import React from 'react';
import ReactDOM from 'react-dom/client';
import NavBar from './components/navbar';
import Rodape from './components/footer';
import Produto from './components/produto';
import './style/global.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <>
    <div className="container-fluid mt-page">
      <NavBar />
        <div className="row justify-content-center text-center">
              <Produto nome_produto="COUVE FLOR" 
                       codigo_produto="7891234" 
                       url_imagem="https://cgnotas.com.br/rastreio/assets/couveflor.jpeg"
                       localizacao="https://cgnotas.com.br/rastreio/assets/mapa.png"  />
                              
        </div> 

        <Rodape />
    </div>    

    </>
); 

