import logo from '../../assets/logo.png'
import logoCgnotas from '../../assets/logo_cgnotas.png'
import './style.css';

function NavBar(){
    return <nav className="navbar fixed-top navbar-expand-lg navbar-light ms-2 me-2 bg-light ps-3 pe-3">
    <div className="container-fluid">
         <img className="mt-1" src={logo} alt="Logo"/>
         <img className="logoCG" src={logoCgnotas} alt="Logo CGCom"/>

         <div class="d-grid gap-1 d-md-flex justify-content-md-end">
         <button class="btn btn-primary me-md-3" type="button">Compartilhar</button>
        </div>
        <div class="d-grid gap-1 d-md-flex justify-content-md-end">
         <button class="btn btn-secondary me-md-3" type="button">Imprimir</button>
        </div>

    
    </div>
  </nav>

}

export default NavBar;