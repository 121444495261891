import './style.css';

function Produto(props){
    return <div className="produto col-sm-6 col-md-4 col-lg-1">
            <div className="dados-produto">
                <p>Informações do produto</p>
                <p> Nome do Produto:  {props.nome_produto} </p> <br />
                <p> Código do Produto: {props.codigo_produto} </p> <br />

            </div> 
               <img className="img-produto" src={props.url_imagem} alt="Produto" /> 
            <br /><br /><br />
            <p>Localização - Origem do produto</p>
            <img className="img-localizacao" src={props.localizacao} alt="Origem" /> 
            

    </div>
}

export default Produto;